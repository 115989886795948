import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import IconButton from '../icon/IconButton';

import './search.scss';

interface Props {
  withBorder?: boolean;
  placeholder?: string;
  submit: (value?: string) => void;
  startValue?: string;
  id?: string;
}

interface State {
  value: string;
}

class Search extends PureComponent<Props, State> {
  static defaultProps: Record<string, unknown>;

  constructor(props: Props) {
    super(props);
    this.state = { value: props.startValue };
  }

  submit = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.submit(this.state.value);
  };

  onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
  };

  onClear = () => {
    this.setState({ value: '' });
    this.props.submit();
  };


  render() {
    const classes = classNames('search-wrapper', {
      'search-wrapper-with-border': this.props.withBorder,
    });

    return (
      <form onSubmit={this.submit} data-testid="search-form">
        <div className={classes}>
          <label htmlFor="search" className="visualy-hidden">Search:</label>
          <input value={this.state.value} className="search-input" type="text" id="search" name={this.props.placeholder} placeholder={this.props.placeholder} onChange={this.onChangeValue}></input>
          {this.state.value &&
            <IconButton tag="ClearIcon" onClick={this.onClear} title={accessibilityLabels.clear} />}
          <IconButton tag="SearchIcon" type="submit" title={accessibilityLabels.search} />
        </div>
      </form>
    );
  }
}

Search.defaultProps = {
  withBorder: true,
  placeholder: '',
  startValue: '',
};

export default Search;
