import React, { BaseSyntheticEvent, FC } from 'react';

import Icon from '../icon/Icon';

import './Checkbox.scss';

interface Props {
  label: string;
  isChecked?: boolean;
  id: string;
  onCheck?: (event: BaseSyntheticEvent) => void;
}

const CheckBox: FC<Props> = ({ isChecked, label, id, onCheck }) => {
  return (
    <div className="checkbox">
      <Icon tag={isChecked ? 'CheckedIcon' : 'UncheckedIcon'} color="primary" />
      <input
        checked={isChecked}
        onChange={onCheck}
        type="checkbox"
        name="Checkbox"
        id={id}
        data-testid="checkbox"
      />
      <label className="label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

const noop = () => null;
CheckBox.defaultProps = {
  isChecked: true,
  onCheck: noop,
};

export default CheckBox;
