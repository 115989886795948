import React, { PureComponent } from 'react';

import Icon from '../icon/Icon';
import Orb from '../orb/Orb';

interface Props {
  onClick: (value: string) => void;
  orb?: string;
  value: string;
  label: string;
  isSelected?: boolean;
  selectedLabel?: string;
}

class Option extends PureComponent<Props> {
  static defaultProps: Record<string, unknown>;

  onClick = () => {
    this.props.onClick(this.props.value);
  }

  render() {
    return (
      <button type="button" className="option" onClick={this.onClick}>
        <div className="option-content">
          {!!this.props.orb && <Orb color={this.props.orb} filled small />}
          <div className="option-label">{this.props.label}</div>
          {this.props.isSelected && <Icon tag="CheckIcon" title={this.props.selectedLabel} />}
        </div>
      </button>
    );
  }
}

Option.defaultProps = {
  orb: '',
  isSelected: false,
};

export default Option;
