import React, { FC, useEffect, useState } from 'react';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import { ILabelAndValue } from '../form/models';
import Icon from '../icon/Icon';
import IconButton from '../icon/IconButton';

import './multiSelect.scss';

type Props = {
  options: ILabelAndValue[];
  values: string[];
  onChange: (value: string) => void;
  label: string;
  placeholder: string;
}

const MultiSelect: FC<Props> = ({ options, values, onChange, label, placeholder }) => {
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, [isOpen]);

  const renderSelectedItem = (value: string) => {
    return <li key={value}>
      <button data-testid="selected" className="stripped-button selected-item" onClick={(event) => onSelect(event, value)} key={value}>
        {
          options.find(o => o.value === value)?.label
        }
        <Icon tag="CrossIcon" small title={accessibilityLabels.remove} />
      </button>
    </li>;
  };

  const onSelect = (event: React.MouseEvent, value: string) => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    onChange(value);
  };

  const renderOption = ({ label, value }: ILabelAndValue) => {
    return (
      <li key={`option-${value}`}>
        <button data-testid="option" className="stripped-button option" onClick={(event) => onSelect(event, value)} key={value}>
          {values.find(v => v === value) ? <Icon data-testid="checked" tag="CheckedIcon" title={accessibilityLabels.checked} /> : <Icon data-testid="unchecked" tag="UncheckedIcon" title={accessibilityLabels.unchecked} />}
          {label}
        </button>
      </li>
    );
  };

  const toggle = (event: React.MouseEvent) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    if (!isOpen) setisOpen(true);
    else setisOpen(false);
  };

  const close = () => {
    setisOpen(false);
  };

  return (
    <div className="multi-select">
      <p className="label">
        {label}
      </p>
      <div className="input" data-testid="selected-options">
        {!values.length ?
          <button className="stripped-button" onClick={toggle}>{!values.length && placeholder}</button>
          :
          <ul className="selected-items">
            {values.map(renderSelectedItem)}
          </ul>
        }
        <IconButton onClick={toggle} tag="ChevronDownIcon" title={placeholder} data-testid="multi-select" />
      </div>

      {
        isOpen &&
        <ul className="options" data-testid="options" >
          {
            options.map(renderOption)
          }
        </ul>
      }
    </div>
  );
};

export default MultiSelect;
