import React, { FC } from 'react';

import { colors } from '../../utils';

import './styles.scss';

interface Props {
  small?: boolean;
  color?: string;
  filled?: boolean;
  size?: number;
}

const Orb: FC<Props> = (props) => {
  let size = 1.2;

  if (props.size) {
    size = props.size;
  } else if (props.small) {
    size = 0.8;
  }

  let backgroundColor = colors.transparent;
  const borderColor = props.color;
  if (props.filled) {
    backgroundColor = props.color;
  }

  return (
    <span
      data-testid="orb"
      className="orb"
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
        borderRadius: `${size / 2}rem`,
        backgroundColor,
        borderColor,
      }} />
  );
};

Orb.defaultProps = {
  small: false,
  color: colors.primary,
  filled: false,
};

export default Orb;
