import React, { FC } from 'react';

import InputGroup, { Props as InputGroupProps } from './InputGroup';
import { ILabelAndValue, InputType } from './models';

import './inputField.scss';

interface Props extends InputGroupProps {
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  onChange: (value: string, name?: string) => void;
  options?: ILabelAndValue[];
  placeholder?: string;
  type: InputType;
  value?: string;
  id?: string;
  maxLength?: number;
  onBlur?: () => void;
}

const InputField: FC<Props> = ({ autoFocus, placeholder, icon, className, emptyIcon, label, onChange, name, maxLength, type, value, options, error, disabled, onBlur }) => {
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    onChange(event.target.value, name);
  };

  const renderOption = (option: ILabelAndValue) => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  };

  const renderInput = () => {
    switch (type) {
      case InputType.Select:
        return (
          <select
            onChange={onChangeInput}
            value={value}
            disabled={disabled}
            name={name}
            id={name}
          >
            {!!placeholder && !value && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {options.map(renderOption)}
          </select>
        );
      case InputType.Password:
        return (
          <input
            lang="en_gb"
            autoFocus={autoFocus}
            placeholder={placeholder}
            type="password"
            name={name}
            id={name}
            value={value as string}
            onChange={onChangeInput}
            disabled={disabled}
            maxLength={maxLength}
          />
        );
      case InputType.Color:
        return (
          <input
            autoFocus={autoFocus}
            placeholder={placeholder}
            type="color"
            name={name}
            id={name}
            value={value}
            onChange={onChangeInput}
            disabled={disabled}
            maxLength={maxLength}
          />
        );
      case InputType.Textarea:
        return <textarea
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          name={name}
          id={name}
          onChange={onChangeInput}
          disabled={disabled}
          maxLength={maxLength}
        />;
      case InputType.Number:
        return (
          <input
            autoFocus={autoFocus}
            placeholder={placeholder}
            type="number"
            name={name}
            id={name}
            value={value}
            onChange={onChangeInput}
            disabled={disabled}
            maxLength={maxLength}
          />
        );
      case InputType.Text:
      default:
        return (
          <input
            onBlur={onBlur}
            autoFocus={autoFocus}
            placeholder={placeholder}
            type="text"
            name={name}
            id={name}
            value={value}
            onChange={onChangeInput}
            disabled={disabled}
            maxLength={maxLength}
          />
        );
    }
  };

  return (
    <InputGroup className={className} icon={icon} emptyIcon={emptyIcon} label={label} type={type} error={error} name={name} >
      {renderInput()}
    </InputGroup>
  );
};

InputField.defaultProps = {
  autoFocus: false,
  placeholder: '',
  className: '',
  icon: '',
  label: null,
  emptyIcon: false,
  value: '',
  options: [],
  error: false,
  disabled: false,
  name: '',
};

export default InputField;
