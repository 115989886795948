export default {
  background: '#f7fbff',
  black: '#000000',
  blackTransparant: 'rgba(0,0,0,0.14)',
  error: '#e84c3d',
  gray: '#efefef',
  gray2: '#a3aab3',
  iosPrimary: '#007AFF',
  primary: '#3399ff',
  primaryDark: '#194d81',
  primaryLight: '#d6e7ff',
  primaryTransparent: 'rgba(51,153,255,0.27)',
  shiftHeader: '#DEECFF',
  success: '#41ba84',
  text: '#2c3b45',
  textGray: '#4a515a',
  textLight: '#a7bdcc',
  transparent: 'rgba(0, 0, 0, 0)',
  primaryLightGray: '#e5ecf5',
  warning: '#ff9633',
  white: '#ffffff',
  whiteTransparent: 'rgba(255,255,248,0.03)',
};
