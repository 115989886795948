import React, { BaseSyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Spinner from '../spinner/Spinner';

import './button.scss';

const THEMES = ['primary', 'secondary', 'inverse', 'transparent'];

export interface Props {
  ahref?: string;
  disabled?: boolean;
  link?: string;
  isLoading?: boolean;
  onClick?: (event: BaseSyntheticEvent) => void;
  theme?: 'primary' | 'secondary' | 'inverse' | 'transparent';
  style?: React.CSSProperties;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
  title?: string;
  'data-testid'?: string;
}

class Button extends React.PureComponent<Props> {
  static defaultProps: Record<string, unknown>;

  renderLoader = () => {
    if (!this.props.isLoading) return null;
    return <div className="spinner-container"><Spinner /></div>;
  }

  renderClickPart = (cssClasses: string) => {
    if (this.props.link) {
      return (
        <Link data-testid={this.props['data-testid']} title={this.props.title} className={cssClasses} to={this.props.link} disabled={this.props.disabled || this.props.isLoading} >
          {this.props.children}
          {this.renderLoader()}
        </Link >
      );
    } else if (this.props.ahref) {
      return (
        <a data-testid={this.props['data-testid']} title={this.props.title} href={this.props.ahref} className={cssClasses} >
          {this.props.children}
          {this.renderLoader()}
        </a >
      );
    }
    return (
      <button data-testid={this.props['data-testid']} title={this.props.title} type={this.props.type} onClick={this.props.onClick} className={cssClasses} style={this.props.style} disabled={this.props.disabled || this.props.isLoading}>
        {this.props.children}
        {this.renderLoader()}
      </button>
    );
  };


  render() {
    const theme = THEMES.includes(this.props.theme) ? this.props.theme : THEMES[0];
    const className = classNames(`btn btn-${theme}`, this.props.className, {
      disabled: this.props.disabled,
      'is-loading': this.props.isLoading,
    });
    return this.renderClickPart(className);
  }
}

const noop = () => null;
Button.defaultProps = {
  ahref: '',
  disabled: false,
  link: '',
  isLoading: false,
  onClick: noop,
  theme: THEMES[0],
  style: {},
  children: null,
  type: 'button',
  className: '',
};

export default Button;
