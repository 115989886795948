import React, { BaseSyntheticEvent } from 'react';

import Icon, { IconProps } from './Icon';

import './iconButton.scss';

interface Props extends IconProps {
  onClick?: (event: BaseSyntheticEvent) => void;
  className?: string;
  iconClassName?: string;
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean;
  title: string;
  testId?: string;
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { onClick, className, iconClassName, disabled, type, testId, ...otherProps } = props;

  return (
    <button data-testid={testId} ref={ref} type={type} disabled={disabled} onClick={onClick} className={`icon-button ${className}`} name={props.title} title={props.title}>
      <Icon {...otherProps} className={iconClassName} title={props.title} />
    </button>
  );
});

IconButton.defaultProps = {
  disabled: false,
  className: '',
  type: 'button',
  iconClassName: '',
};

export default IconButton;
