import React from 'react';
import classnames from 'classnames';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import IconButton from '../icon/IconButton';

import './FormattedValue.scss';

export const counterTypes = { TIME: 'time', PERCENTAGE: 'percentage', OTHER: 'other' };

export enum CounterType {
  Time = 'time',
  Percentage = 'percentage',
  Other = 'other'
}

interface Props {
  value?: { minutes?: number; hours?: number; percentage?: number };
  type?: CounterType;
  description?: string;
  style?: React.CSSProperties;
  large?: boolean;
  isEditing?: boolean;
  onRemove?: () => void;
}

class FormattedValue extends React.PureComponent<Props> {
  static defaultProps: Record<string, unknown>;

  renderFormattedString = (bigPart: string, smallPart: string, separator: string, unit?: string) => {
    return (
      <span className="text-container">
        {bigPart}
        <span className="text-container">
          <p className="separator">{separator}</p>
          <p className="small-part">{smallPart}</p>
        </span>
        {!!unit && <p className="unit">{unit}</p>}
      </span>
    );
  }

  renderPrecentage = () => {
    const { percentage } = this.props.value;
    return this.splitPercentageAndFormat((percentage || '').toString());
  }

  renderTime = () => {
    const minutes = this.props.value.minutes < 10 ? `0${this.props.value.minutes}` : `${this.props.value.minutes}`;
    return this.renderFormattedString(String(this.props.value.hours || 0), isNaN(parseInt(minutes)) ? '00' : minutes, ':');
  }

  splitPercentageAndFormat = (percentage: string) => {
    if (!!percentage) {
      let [beforeComma, afterComma] = percentage.split('.');

      if (!afterComma) {
        afterComma = '00';
      } else if (afterComma.length === 1) {
        afterComma = `${afterComma}0`;
      }
      return this.renderFormattedString(beforeComma, afterComma, ',', '%');
    }
    return null;
  };

  render() {
    const className = classnames('formatted-value-container', { large: this.props.large });
    return (
      <div className={className}>
        {
          this.props.isEditing &&
          <div className="close-button">
            <IconButton tag="CrossIcon" color="error" onClick={this.props.onRemove} title={accessibilityLabels.close} />
          </div>
        }
        <div className="value-container">
          {this.props.type === CounterType.Time && this.renderTime()}
          {this.props.type === CounterType.Percentage && this.renderPrecentage()}
        </div>
        <div>
          <p className="description">
            {this.props.description}
          </p>
        </div>
      </div>
    );
  }
}

const noop = () => null;
FormattedValue.defaultProps = {
  style: {},
  large: false,
  isEditing: false,
  onRemove: noop,
  value: '',
  type: CounterType.Other,
};

export default FormattedValue;
