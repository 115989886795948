import React, { BaseSyntheticEvent, PureComponent, ReactNode } from 'react';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import Button from '../button/Button';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import Icon from '../icon/Icon';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';

import './fileUpload.scss';

interface TransitionLabels {
  lblError?: string;
  lblErrorFileUploadSize?: string;
  lblDrag?: string;
  lblBtn?: string;
  lblTitle?: string;
  lblRemoveImage?: string;
}

interface Props {
  acceptFileTypes?: string;
  handleUpload?: (file: File) => void;
  initialFile?: string;
  isError?: boolean;
  isLoading?: boolean;
  isModal?: boolean;
  isVisible?: boolean;
  maxFileSize?: number;
  showPreview?: boolean;
  toggleModal?: () => void;
  translationLabels?: TransitionLabels;
  title?: string;
}

interface State {
  file: File;
  isFileSizeError: boolean;
}

class FileUpload extends PureComponent<Props, State> {
  static defaultProps: Record<string, unknown>;

  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      isFileSizeError: false,
    };
  }

  handlePick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isFileSizeError: false }); // clear error state
    const files = Array.from(e.target.files);
    if (files && files[0]) {
      if (this.props.maxFileSize && files[0].size > this.props.maxFileSize) {
        return this.setState({ isFileSizeError: true });
      }

      this.setState({ file: files[0] });
      this.props.handleUpload(files[0]);
    }
  }

  removeFile = (): void => {
    this.setState({ file: null, isFileSizeError: false });
    this.props.handleUpload(null); // Remove image
  }

  handleDrop = (file: File): void => {
    if (this.props.maxFileSize && file.size > this.props.maxFileSize) return this.setState({ isFileSizeError: true });
    if (this.props.acceptFileTypes && !this.props.acceptFileTypes.includes(file.type)) return;
    this.setState({ file });
    this.props.handleUpload(file);
  }

  openFile = (event: BaseSyntheticEvent): void => {
    event.preventDefault();
    document.getElementById('file').click();
  }

  renderDropZone = (): ReactNode => {
    return (
      <DragAndDrop onHandleDrop={this.handleDrop}>
        <>
          <div className="error-container">
            {this.props.isError && <span>{this.props.translationLabels.lblError}</span>}
            {this.state.isFileSizeError && <span>{this.props.translationLabels.lblErrorFileUploadSize}</span>}
          </div>
          <p>{this.props.translationLabels.lblDrag}</p>
          <div>
            <input type="file" id="file" data-testid="file" onChange={this.handlePick} accept={this.props.acceptFileTypes} />
            <Button onClick={this.openFile} data-testid="file-button" title={this.props.title} >
              <label htmlFor="file" id="file-label" >{this.props.translationLabels.lblBtn}</label>
            </Button>
          </div>
        </>
      </DragAndDrop >
    );
  }

  renderLoader = (): ReactNode => {
    return <div className="loader-container"><Spinner /></div>;
  }

  renderFileUpload = (): ReactNode => {
    return (<div data-testid="fileupload" className="picker-container">
      {this.props.isModal && <div className="picker-header">
        <h3>{this.props.translationLabels.lblTitle}</h3>
        <Button theme="transparent" onClick={this.props.toggleModal}>
          <Icon tag="CrossIcon" color="error" title={accessibilityLabels.close} />
        </Button>
      </div>}
      {this.props.isLoading ? this.renderLoader() : this.renderDropZone()}
      {this.props.showPreview && <div className="file-preview-container">
        {(this.state.file || this.props.initialFile) &&
          <React.Fragment>
            <img className="image-container" src={this.state.file ? URL.createObjectURL(this.state.file) : this.props.initialFile} alt="uploaded" />
            <p data-testid="remove-preview" className="lbl-remove-image" onClick={this.removeFile}>{this.props.translationLabels.lblRemoveImage}</p>
          </React.Fragment>
        }
      </div>}
    </div>);
  }

  render(): ReactNode {
    const { isModal } = this.props;
    return (
      <React.Fragment>
        {isModal && <Modal open={this.props.isVisible} requestClose={this.props.toggleModal}>
          {this.renderFileUpload()}
        </Modal>}
        {!isModal && this.renderFileUpload()}
      </React.Fragment>
    );
  }
}

FileUpload.defaultProps = {
  acceptFileTypes: '',
  initialFile: '',
  isError: false,
  isLoading: false,
  isModal: true,
  isVisible: false,
  maxFileSize: null,
  showPreview: true,
  toggleModal: null,
  translationLabels: {},
};

export default FileUpload;
