import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import Avatar from '../avatar/Avatar';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import Modal from '../modal/Modal';
import Search from '../search/Search';

import '../../styling/defaults.scss';
import './selectUserModal.scss';

interface IUser {
  id: string;
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
}

interface Props {
  isOpen?: boolean;
  requestClose: () => void;
  onSearch?: (value: string) => void;
  onSelect: (value: string | string[], departmentId?: string) => void;
  users: IUser[] | ISection[];
  selectedUsers?: string[];
  title: string;
  searchLabel?: string;
  isLoading?: boolean;
  isSectioned?: boolean;
  isMultiSelect?: boolean;
  isSaveLoading?: boolean;
  showSelectAll?: boolean;
  deselectLabel?: string;
  selectLabel?: string;
  cancelLabel?: string;
  saveLabel?: string;
  userIdentifier?: string;
}

interface ISection {
  department: { name: string; id: string; };
  users: IUser[];
}

interface State {
  users: IUser[] | ISection[];
  collapsedItems: string[];
  selectedUsers: string[];
}

class SelectUserModal extends PureComponent<Props, State> {
  static defaultProps: Record<string, unknown>;
  private selectedDepartmentId: string;

  constructor(props: Props) {
    super(props);
    this.state = { users: this.formatUsers(), collapsedItems: [], selectedUsers: props.selectedUsers };
    this.selectedDepartmentId = null;
  }


  componentDidUpdate(prevProps: Props) {
    if (prevProps.users?.length !== this.props.users.length || ((prevProps.users[0] && this.props.users[0]) && prevProps.users[0][this.props.userIdentifier] !== this.props.users[0][this.props.userIdentifier])) {
      this.setState({
        users: this.formatUsers(),
      });
    }

    if (prevProps.isOpen && !this.props.isOpen) this.clearSelection();
  }

  formatUsers = () => {
    if (!this.props.users) return [];
    return !this.props.isSectioned
      ? (this.props.users as IUser[]).map(user => ({ ...user }))
      : (this.props.users as ISection[]).reduce((accu, section) => [...accu, ...section.users.map(user => ({ ...user }))], []);
  }

  onSave = () => {
    this.props.onSelect(this.props.isMultiSelect ? this.state.selectedUsers : this.state.selectedUsers[0], this.selectedDepartmentId);
  }

  requestClose = () => {
    this.clearSelection();
    this.props.requestClose();
  }

  clearSelection = () => {
    this.setState({ selectedUsers: this.props.selectedUsers });
  }

  onSelect = (user: IUser) => () => {
    const identifier = this.props.userIdentifier;
    let selectedUsers = [...this.state.selectedUsers];
    const index = selectedUsers.indexOf(user[identifier]);
    if (!this.props.isMultiSelect) {
      selectedUsers = [user[identifier]];
    } else {
      if (index >= 0) {
        selectedUsers.splice(index, 1);
      } else {
        // If we have sections, unselect users from other sections, since they can't be combined
        if (this.props.isSectioned) {
          const section = (this.props.users as ISection[]).find(section => section.users.some(u => u[identifier] === user[identifier]));
          selectedUsers = selectedUsers.filter(userId => section.users.some(u => u[identifier] === userId));
        }
        selectedUsers.push(user[identifier]);
      }
    }

    this.setState({ selectedUsers });
  }

  renderUser = (user: IUser) => {
    const isSelected = this.state.selectedUsers.find((id) => id === user[this.props.userIdentifier]);

    return (
      <button className="stripped-button user" key={user[this.props.userIdentifier]} onClick={this.onSelect(user)}>
        <Avatar firstName={user.firstName} lastName={user.lastName} isSelected={!!isSelected} source={user.profilePictureUrl} />
        <p className="user-name">{`${user.lastName || ''} ${user.firstName || ''}`}</p>
      </button>
    );
  }

  toggleCollapse = (id: string) => () => {
    const ids = [...this.state.collapsedItems];
    const index = ids.indexOf(id);

    if (index >= 0) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }

    this.setState({ collapsedItems: ids });
  }

  isFullSectionSelected = (section: ISection) => {
    return section.users && section.users.length > 0 && section.users.every(user => this.state.selectedUsers.includes(user[this.props.userIdentifier]));
  }

  selectSection = (sectionIds: string[]) => {
    this.setState({ selectedUsers: sectionIds });
  }

  renderSection = (section: ISection) => {
    const isCollapsed = this.state.collapsedItems.find(id => id === section.department.id);
    const isFullSectionSelected = this.isFullSectionSelected(section);
    if (this.props.showSelectAll) {
      if (isFullSectionSelected) {
        this.selectedDepartmentId = section.department.id;
      } else if (this.selectedDepartmentId === section.department.id) {
        this.selectedDepartmentId = null;
      }
    }
    return (
      <div className="section" key={section.department.id}>
        <button className="stripped-button section-header" onClick={this.toggleCollapse(section.department.id)}>
          <Icon color="primary" tag={!isCollapsed ? 'ChevronDownIcon' : 'ChevronUpIcon'} title={!isCollapsed ? accessibilityLabels.collapse : accessibilityLabels.expand} />
          <p>{section.department.name}</p>
        </button>
        {this.props.showSelectAll && (
          <Button className="side-button" theme={isFullSectionSelected ? 'primary' : 'inverse'} onClick={(event) => {
            event.stopPropagation();
            if (isFullSectionSelected) this.selectSection([]);
            else this.selectSection(section.users.map(u => u[this.props.userIdentifier]));
          }}>
            {isFullSectionSelected ? this.props.deselectLabel : this.props.selectLabel}
          </Button>
        )}
        <div className="section-users">
          {!isCollapsed && section.users && section.users.map(this.renderUser)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="select-modal">
        <Modal title={this.props.title} open={this.props.isOpen} requestClose={this.requestClose}>
          <div className="participants">
            {!!this.props.searchLabel && <Search placeholder={this.props.searchLabel} submit={this.props.onSearch} />}
            <div className="content">
              <div className="participants">
                {
                  this.props.isLoading
                  &&
                  <React.Fragment>
                    <div data-testid="skeleton" className="skeleton" >
                      <Skeleton height="4rem" />
                    </div>
                    <div className="skeleton" >
                      <Skeleton height="4rem" />
                    </div>
                    <div className="skeleton" >
                      <Skeleton height="4rem" />
                    </div>
                  </React.Fragment>
                }
                {
                  !this.props.isLoading
                  &&
                  <React.Fragment>
                    {
                      (this.state.users && this.props.isSectioned)
                        ?
                        (this.props.users as ISection[]).map(this.renderSection)
                        :
                        (this.state.users as IUser[]).map(this.renderUser)
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
          <footer>
            <Button theme="inverse" onClick={this.requestClose}>{this.props.cancelLabel}</Button>
            <Button disabled={!this.state.selectedUsers.length} isLoading={this.props.isSaveLoading} onClick={this.onSave}>{this.props.saveLabel}</Button>
          </footer>
        </Modal>
      </div>
    );
  }
}

SelectUserModal.defaultProps = {
  isOpen: true,
  searchLabel: '',
  isLoading: false,
  isSectioned: false,
  isMultiSelect: true,
  isSaveLoading: false,
  selectedUsers: [],
  showSelectAll: false,
  deselectLabel: '',
  selectLabel: '',
  userIdentifier: 'id',
};

export default SelectUserModal;
