import React, { FC } from 'react';

import './radioButton.scss';

type Props = {
  label?: string,
  checked?: boolean,
  onChange?: (e) => void,
  value?: string,
  name?: string,
};

const RadioButton: FC<Props> = ({ label, checked, onChange, value, name }) => (
  <div className="radio-button">
    <label className="container">
      <span>{label}</span>
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <span className="checkmark"></span>
    </label>
  </div>
);

export default RadioButton;
