import React, { FC } from 'react';
import classnames from 'classnames';

import Icon from '../icon/Icon';
import { InputDateType, InputType } from './models';

export interface Props {
  icon?: string;
  className?: string;
  emptyIcon?: boolean;
  label?: string;
  type?: InputType | InputDateType;
  error?: boolean;
  name?: string
}

const InputGroup: FC<Props> = ({ className, icon, emptyIcon, label, type, error, children, name }) => {
  const labelClassName = classnames(
    'input-field-label',
    error && 'input-field-label-error'
  );
  const inputClassName = classnames(
    `input-field-control input-${type}`,
    error && 'input-field-input-error'
  );
  return (
    <section className={`input-field ${className} input-field-${type}`}>
      {!emptyIcon && (
        <div className="input-field-icon">
          <Icon tag={icon} />
        </div>
      )}
      {!!label && <label className={labelClassName} htmlFor={name}>{label}</label>}
      <div className={inputClassName}>{children}</div>
    </section>
  );
};

export default InputGroup;
