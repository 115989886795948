import React from 'react';

export const COLORS = [
  { colorName: 'light-blue', color: '#d6e7ff' },
  { colorName: 'green', color: '#8dcc4e' },
  { colorName: 'dark-yellow', color: '#ffc233' },
  { colorName: 'pink', color: '#ce4b99' },
  { colorName: 'purple', color: '#444b99' },
  { colorName: 'lightGreen', color: '#cee299' },
];

const RADIUS = 15.91549430918954; // r = C/(2π) (C = circumference = 100 for easy calculations)
const FIRST_SEGMENT_OFFSET = 23; // We start drawing in the middle right of the circle -> 25 to start at the top -> 23 because there is a margin of 2 on the left and the right of a segment
const SPACING_BETWEEN_SEGMENTS = 4;
export const VIEWBOX = 34;

interface Props {
  values: number[];
}

class CircleGraph extends React.PureComponent<Props> {
  renderCircleComponent = (dashArray: string, offset: number, color: string, cx: number, cy: number, radius: number, key: number) => {
    return <circle
      key={key}
      className="graph-segment"
      cx={cx}
      cy={cy}
      strokeLinecap="round"
      r={radius}
      fill="transparent"
      stroke={color}
      strokeWidth="1.5"
      strokeDasharray={dashArray}
      strokeDashoffset={offset} />;
  }

  getPercentagesWithSpacings = () => {
    // Filter out the segments with length 0
    const segmentCountWithContent = this.props.values.filter(value => value).length;
    // If there is only one segement, we do nothing
    if (segmentCountWithContent <= 1) return this.props.values;
    // We add a spacing of 4 after each segment, so our segments should be reduced
    const extraSpacing = segmentCountWithContent * SPACING_BETWEEN_SEGMENTS;
    return this.props.values.map(value => ((value * (100 - extraSpacing)) / 100));
  }

  renderCircle = (preceding_segments_length: number) => (value: number, i: number) => {
    if (value === 0) return null;
    let offset = FIRST_SEGMENT_OFFSET;
    if (i !== 0) {
      // Current segment offset = circumference − all preceding segments total length + first segment's offset
      offset = 100 - preceding_segments_length + FIRST_SEGMENT_OFFSET;
    }
    preceding_segments_length += value + SPACING_BETWEEN_SEGMENTS;
    const dashArray = `${value} ${100 - value}`;
    return this.renderCircleComponent(dashArray, offset, COLORS[i].color, VIEWBOX / 2, VIEWBOX / 2, RADIUS, i);
  }

  render() {
    const values = this.getPercentagesWithSpacings();
    return (
      <svg data-testid="circle-graph" width="100%" height="100%" viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`} className="graph">
        {values.map(this.renderCircle(0))}
      </svg>
    );
  }
}

export default CircleGraph;
