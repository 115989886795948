import React from 'react';

import * as ICONS from '../../assets/icons';

import './icon.scss';

export interface IconProps {
  customSize?: number;
  tag: string;
  className?: string;
  color?: string;
  small?: boolean;
  large?: boolean;
  style?: React.CSSProperties;
  title?: string;
  keepOriginalColor?: boolean;
}

const Icon = React.forwardRef<HTMLButtonElement, IconProps>(({ customSize, tag, color, small, large, style, className, title, keepOriginalColor, ...otherProps }, ref) => {
  const getImageSize = () => {
    if (customSize) {
      return customSize;
    }
    if (small) {
      return 1.6;
    }
    if (large) {
      return 3.2;
    }
    return 2;
  };

  const size = getImageSize();
  const Icon = ICONS[tag];
  if (!Icon) {
    return null;
  }

  return (
    <i ref={ref} {...otherProps} className={`icon icon-${tag} icon-${color} ${keepOriginalColor ? 'keep-original-color' : ''} ${className}`} style={{ fontSize: `${(size - (size * 0.1)).toFixed(1)}rem`, width: `${size}rem`, height: `${size}rem`, ...style }}>
      <Icon title={title} />
    </i>
  );
});

Icon.defaultProps = {
  customSize: undefined,
  color: '',
  className: '',
  small: false,
  large: false,
  style: {},
};

export default Icon;
