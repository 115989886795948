export type AccessibilityLabels = {
  checked: string;
  clear: string;
  close: string;
  collapse: string;
  expand: string;
  remove: string;
  unchecked: string;
  search: string;
}
export const accessibilityLabels: AccessibilityLabels = {
  checked: '',
  clear: '',
  close: '',
  collapse: '',
  expand: '',
  remove: '',
  unchecked: '',
  search: '',
};
export const initAccessibilityLabels = (labels: AccessibilityLabels) => {
  Object.keys(labels).forEach(key => accessibilityLabels[key] = labels[key]);
};