import React, { FC } from 'react';

import './spinner.scss';

const Spinner: FC<{ solid?: boolean }> = ({ solid }) => {
  if (solid) {
    return <div data-testid="spinner-solid" className="spinner-solid"><div></div><div></div><div></div><div></div></div>;
  }
  return (
    <div data-testid="spinner" className="spinner">
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
      <div className="bar4"></div>
      <div className="bar5"></div>
      <div className="bar6"></div>
      <div className="bar7"></div>
      <div className="bar8"></div>
      <div className="bar9"></div>
      <div className="bar10"></div>
      <div className="bar11"></div>
      <div className="bar12"></div>
    </div>
  );
};

Spinner.defaultProps = {
  solid: false,
};

export default Spinner;
