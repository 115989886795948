import React, { FC } from 'react';
import DatePickerOriginal, { ReactDatePickerProps,registerLocale } from 'react-datepicker';
import { de, enGB,fr, nl } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';

export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Locale = 'en' | 'de' | 'fr' | 'nl';

registerLocale('en', enGB);
registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('nl', nl);

const languages = {
  en: enGB,
  de: de,
  fr: fr,
  nl: nl,
};

interface Props extends ReactDatePickerProps {
  weekStartsOn?: WeekStartsOn;
  locale?: Locale;
}

const DatePicker: FC<Props> = ({ locale, weekStartsOn, ...otherProps }) => {
  registerLocale(locale, { ...languages[locale], options: { weekStartsOn: weekStartsOn } });
  return (
    <div className="datepicker-wrapper">
      <DatePickerOriginal
        locale={{ ...languages[locale], options: { weekStartsOn: weekStartsOn } }}
        {...otherProps}
      />
    </div>
  );
};

DatePicker.defaultProps = {
  weekStartsOn: 1,
  locale: 'en',
};

export default DatePicker;
