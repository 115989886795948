import React, { FC, useEffect, useRef } from 'react';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import IconButton from '../icon/IconButton';

import './popup.scss';

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
  TopCenter = 'top-center',
  Modal = 'modal',
  RightTop = 'right-top',
  RightBottom = 'right-bottom',
  TopLeft = 'top-left',
}

interface Props {
  position?: Position;
  children?: React.ReactNode;
  open?: boolean;
  style?: React.CSSProperties;
  requestClose?: () => void;
  excludePressArea?: HTMLElement[];
  isDropdown?: boolean;
  isModal?: boolean;
}

const Popup: FC<Props> = ({
  position,
  children,
  open,
  style,
  requestClose,
  excludePressArea,
  isDropdown,
  isModal,
}) => {
  const popupRef = useRef(null);
  const closeButton = useRef(null);

  useEffect(() => {
    if (open && popupRef.current) {
      const elements = popupRef.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="password"], input[type="radio"], input[type="checkbox"], select'
      );

      if (elements && elements[0]) {
        elements[0].focus();
      }

      document.addEventListener('mousedown', handleClick);
      document.addEventListener('keydown', keyListener);

      return () => {
        document.removeEventListener('mousedown', handleClick);
        document.removeEventListener('keydown', keyListener);
      };
    } else {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', keyListener);
    }
  }, [popupRef, closeButton, open]);

  const keyListener = (e) => {
    const listener = keyListenersMap.get(e.keyCode);
    return listener && listener(e);
  };

  const handleTabKey = e => {
    const focusableModalElements = popupRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="password"], input[type="radio"], input[type="checkbox"], select'
    );

    if (focusableModalElements && focusableModalElements.length > 0) {
      const firstElement = focusableModalElements[0];
      let lastElement = null;
      focusableModalElements.forEach(node => {
        if (!node.disabled) lastElement = node;
      });

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      } else if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }
    }
  };

  const keyListenersMap = new Map([[27, requestClose], [9, handleTabKey]]);

  const handleClick = (e) => {
    // Keep the conditions in different if statements to keep it readable
    if (popupRef && popupRef.current.contains(e.target)) {
      return false;
    }
    if (excludePressArea && excludePressArea.length && excludePressArea.find(area => (area && area.contains && area.contains(e.target)))) return false;

    requestClose();
  };

  if (!open) return null;

  return (
    <div style={style} className={`popup ${position}`} ref={popupRef} data-testid="popup" role="dialog" aria-modal="true">
      {
        (!isDropdown && !isModal) &&
        <div className="close" ref={closeButton}>
          <IconButton tag="CrossIcon" onClick={requestClose} title={accessibilityLabels.close} testId="close" />
        </div>
      }
      {children}
    </div >
  );
};

const noop = () => null;
Popup.defaultProps = {
  position: Position.Top,
  children: null,
  open: false,
  style: {},
  requestClose: noop,
  excludePressArea: null,
};

export default Popup;
