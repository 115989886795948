import React, { FC } from 'react';

import DatePicker, { Locale, WeekStartsOn } from '../datepicker/DatePicker';
import Icon from '../icon/Icon';
import InputGroup, { Props as InputGroupProps } from './InputGroup';
import { IDateTime, InputDateType } from './models';

import './inputField.scss';

interface Props extends InputGroupProps {
  autoFocus?: boolean;
  placeholder?: string;
  onChange: (value: IDateTime, name?: string) => void;
  name?: string;
  type?: InputDateType;
  value?: IDateTime;
  dateFormat?: string;
  disabled?: boolean;
  disabledDay?: boolean;
  filterDate?: (date: Date) => boolean;
  locale?: Locale;
  weekStartsOn?: WeekStartsOn;
  maxDate?: Date;
  minDate?: Date;
}

const InputDateField: FC<Props> = ({ autoFocus, maxDate, minDate, placeholder, icon, className, emptyIcon, label, onChange, name, type, value, dateFormat, error, disabled, disabledDay, filterDate, locale, weekStartsOn }) => {
  const onChangeDay = (date: Date) => {
    onChange({ ...value, date }, name);
  };

  const onChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, time: event.target.value }, name);
  };

  return (
    <InputGroup className={className} icon={icon} emptyIcon={emptyIcon} label={label} type={type} error={error}>
      {(type === InputDateType.DateTime || type === InputDateType.Date) ? <div className="input-datepicker">
        <DatePicker
          autoFocus={autoFocus}
          placeholderText={placeholder}
          weekStartsOn={weekStartsOn}
          locale={locale}
          dateFormat={dateFormat}
          onChange={onChangeDay}
          selected={value.date}
          disabled={disabled || disabledDay}
          filterDate={filterDate}
          maxDate={maxDate}
          minDate={minDate}
        />
        <Icon
          tag="CalendarIcon"
          color={
            disabled || disabledDay
              ? 'gray2'
              : 'primary'
          }
        />
      </div>
        :
        <div data-testid="disabled-date" className="disabled-date">{value.date.getDate() + '-' + (value.date.getMonth() + 1) + '-' + value.date.getFullYear()}</div>}
      {(type === InputDateType.DateTime || type === InputDateType.Time) &&
        <input
          disabled={disabled}
          type="time"
          name={name}
          maxLength={5}
          value={value.time}
          className="input-time-field"
          onChange={onChangeTime}
        />
      }
    </InputGroup>
  );
};

InputDateField.defaultProps = {
  autoFocus: false,
  placeholder: '',
  className: '',
  icon: '',
  type: InputDateType.Date,
  label: null,
  emptyIcon: false,
  value: { date: null, time: '' },
  dateFormat: 'dd-MM-yyyy',
  error: false,
  disabled: false,
  disabledDay: false,
  filterDate: undefined,
  locale: 'en',
  name: '',
  weekStartsOn: 1,
};

export default InputDateField;
