import React, { PureComponent } from 'react';

import Option from './Option';

import './select.scss';

export interface ILabelAndValue {
  value: string;
  label: string;
  orb?: string;
}

interface Props {
  values: ILabelAndValue[];
  onChange: (value: string) => void;
  selected?: string | string[];
  isMultiple?: boolean;
  selectedLabel?: string;
}

class Select extends PureComponent<Props> {
  static defaultProps: Record<string, unknown>;

  isSelected = (item: ILabelAndValue) => {
    if (this.props.isMultiple && Array.isArray(this.props.selected)) return this.props.selected.includes(item.value);
    return this.props.selected === item.value;
  }
  renderItems = (item: ILabelAndValue) => {
    return (
      <Option
        key={item.value}
        onClick={this.onClick}
        isSelected={this.isSelected(item)}
        value={item.value}
        label={item.label}
        orb={item.orb}
        selectedLabel={this.props.selectedLabel}
      />
    );
  }

  onClick = (value: string) => {
    this.props.onChange(value);
  }

  render() {
    return (
      <div className="select">
        {this.props.values.map(this.renderItems)}
      </div>
    );
  }
}

Select.defaultProps = {
  selected: undefined,
  isMultiple: false,
};

export default Select;
