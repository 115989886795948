import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import Icon from '../icon/Icon';

import './Avatar.scss';

interface Props {
  firstName?: string;
  lastName?: string;
  source?: string;
  border?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  isSelected?: boolean;
  isCompany?: boolean;
  isGroup?: boolean;
}

interface State {
  didImageLoad: boolean;
  isImageError: boolean;
}


export default class Avatar extends PureComponent<Props, State> {
  static defaultProps: Record<string, unknown>;

  constructor(props: Props) {
    super(props);

    this.state = {
      didImageLoad: false,
      isImageError: false,
    };
  }

  getSize = () => {
    let size = 4;
    if (this.props.small) {
      size = 2.2;
    }
    if (this.props.medium) {
      size = 3.6;
    }
    if (this.props.large) {
      size = 12.8;
    }
    return size;
  };

  getFontSize = () => {
    let fontSize = 1.5;
    if (this.props.small) {
      fontSize = 0.8;
    }
    if (this.props.medium) {
      fontSize = 1.2;
    }
    if (this.props.large) {
      fontSize = 5.4;
    }
    return fontSize;
  };

  getAvatar = () => {
    const { isCompany, isGroup, source, firstName, lastName } = this.props;
    const size = this.getSize();
    const style = {
      width: `${size + 0.1}rem`,
      height: `${size + 0.1}rem`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    if (isGroup) {
      return <Icon tag="GroupIcon" color="white" style={style} large={this.props.large} small={this.props.small} />;
    } else if (isCompany) {
      return <Icon tag="CompanyIcon" color="white" style={style} large={this.props.large} small={this.props.small} />;
    } else if (!!source && !this.state.isImageError) {
      return (
        <React.Fragment>
          {!this.state.didImageLoad && <Skeleton width={this.getSize() * 10} height={this.getSize() * 10} circle={true} />}
          <img className="image" src={source} alt="avatar" style={style} onLoad={this.onImageLoad} onError={this.onImageError} />
        </React.Fragment>
      );
    }

    return <p style={{ fontSize: `${this.getFontSize()}rem` }}> {`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}</p>;
  }

  onImageLoad = () => this.setState({ didImageLoad: true });
  onImageError = () => this.setState({ isImageError: true });

  render() {
    const size = this.getSize();

    const style = {
      width: `${size}rem`,
      height: `${size}rem`,
    };


    const className = classnames('avatar', {
      border: this.props.border,
      'image-background': !!this.props.source && !this.state.didImageLoad,
      'normal-background': !this.props.source || this.state.isImageError,
    });
    return (
      <div data-testid="avatar" className={className} style={{ ...style, borderRadius: `${Math.ceil(size / 2)}rem` }}>
        {
          this.getAvatar()
        }
        {
          this.props.isSelected &&
          <div className="selected image" style={{
            width: `${size + 0.1}rem`,
            height: `${size + 0.1}rem`,
          }} >
            <Icon tag="CheckIcon" color="white" title={accessibilityLabels.checked} />
          </div>
        }
      </div>
    );
  }
}

Avatar.defaultProps = {
  source: '',
  firstName: '',
  lastName: '',
  border: false,
  small: false,
  medium: false,
  large: false,
  isSelected: false,
  isCompany: false,
  isGroup: false,
};
