import React from 'react';

import Modal from '../modal/Modal';
import Popup, { Position } from '../popup/Popup';
import Search from '../search/Search';
import Select, { ILabelAndValue } from '../select/Select';

import './selectPopup.scss';

interface Props {
  data: ILabelAndValue[],
  isSearchEnabled?: boolean;
  popupOpen: boolean;
  selected?: string | string[];
  onValueSelected?: (value: string | string[]) => void;
  isMultiple?: boolean;
  isModal?: boolean;
  isAllowedEmpty?: boolean;
  title?: string;
  cancelLabel?: string;
  acceptLabel?: string;
  searchLabel?: string;
  requestClose?: () => void;
  onSave?: (value: string | string[]) => void;
  onSearch?: (value: string) => void;
  excludePressArea?: HTMLElement;
  selectedLabel?: string;
}

class SelectPopup extends React.PureComponent<Props> {
  static defaultProps: Record<string, unknown>;

  onChange = (value: string) => {
    if (this.props.isMultiple) {
      if (this.props.selected.includes(value)) {
        this.props.onValueSelected((this.props.selected as string[]).filter(item => item !== value));
      }
      else this.props.onValueSelected([...(this.props.selected as string[]), value]);
    } else {
      this.props.onValueSelected(value);
    }
  }

  renderContent = () => (
    <div className="popup-inner-wrapper">
      {this.props.isSearchEnabled && <Search submit={this.props.onSearch} placeholder={this.props.searchLabel} />}
      <div className="select-wrapper">
        <Select selectedLabel={this.props.selectedLabel} values={this.props.data} selected={this.props.selected} isMultiple={this.props.isMultiple} onChange={this.onChange} />
      </div>
    </div>
  );

  onSave = () => this.props.onSave(this.props.selected);

  cancel = () => this.props.requestClose()

  getLeftButtonprops = () => ({ label: this.props.cancelLabel, onClick: this.cancel });

  getRightButtonProps = () => ({ label: this.props.acceptLabel, onClick: this.onSave, disabled: this.props.isMultiple ? (!this.props.selected || this.props.selected.length === 0) && !this.props.isAllowedEmpty : !this.props.selected });

  render() {
    return (
      <div className="select-popup-wrapper">
        {!this.props.isModal &&
          <Popup open={this.props.popupOpen} position={Position.TopCenter} requestClose={this.props.requestClose} excludePressArea={[this.props.excludePressArea]}>
            {this.renderContent()}
          </Popup>
        }

        {this.props.isModal &&
          <Modal open={this.props.popupOpen} title={this.props.title} leftButtonProps={this.getLeftButtonprops()} rightButtonProps={this.getRightButtonProps()} requestClose={this.props.requestClose}>
            {this.renderContent()}
          </Modal>
        }

      </div>
    );
  }
}

const noop = () => null;
SelectPopup.defaultProps = {
  isSearchEnabled: true,
  selected: null,
  onValueSelected: noop,
  isMultiple: false,
  isModal: false,
  isAllowedEmpty: false,
  title: null,
  requestClose: noop,
  onSave: noop,
  cancelLabel: '',
  acceptLabel: '',
  excludePressArea: null,
};

export default SelectPopup;
