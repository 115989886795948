import React from 'react';
import classnames from 'classnames';

import './dragAndDrop.scss';

interface Props {
  onHandleDrop?: (file: File) => void;
  children: React.ReactNode;
}

interface State {
  isDragging: boolean;
}

class DragAndDrop extends React.PureComponent<Props, State> {
  static defaultProps: Record<string, unknown>;
  private dragCounter: number;
  private dropRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.dropRef = React.createRef();

    this.state = {
      isDragging: false,
    };
  }

  handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer?.items?.length > 0) {
      this.setState({ isDragging: true });
    }
  }

  handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ isDragging: false });
    }
  }

  handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isDragging: false });
    if (e.dataTransfer?.files?.length > 0) {
      this.props.onHandleDrop(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  componentDidMount() {
    this.dragCounter = 0;
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    const classes = classnames('drop-hover-container',
      this.state.isDragging && 'drop-container-primary-background'
    );

    return (
      <div data-testid="drag-drop" className="drop-container" ref={this.dropRef}>
        <div className={classes}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const noop = () => null;
DragAndDrop.defaultProps = {
  onHandleDrop: noop,
  children: null,
};

export default DragAndDrop;
