import React, { FC, useEffect } from 'react';

import { accessibilityLabels } from '../../utils/accessibilityLabels';
import Button, { Props as ButtonProps } from '../button/Button';
import IconButton from '../icon/IconButton';
import Popup, { Position } from '../popup/Popup';

import './modal.scss';

export interface ModalButton extends ButtonProps {
  label: string;
}

interface Props {
  title?: string;
  className?: string;
  leftButtonProps?: ModalButton;
  rightButtonProps?: ModalButton;
  children?: React.ReactNode;
  open?: boolean;
  requestClose?: () => void;
}

const Modal: FC<Props> = ({
  title,
  className,
  leftButtonProps,
  rightButtonProps,
  children,
  open,
  requestClose,
  ...otherProps
}) => {
  // Always make sure we reset to auto overflow body on unmount
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (rightButtonProps && !rightButtonProps.disabled && !rightButtonProps.isLoading) {
      rightButtonProps.onClick?.(event);
    }
  };

  if (!open) return null;
  return (
    <div data-testid="modal" className={`modal-container ${className}`} aria-modal="true" role="dialog">
      <Popup {...otherProps} requestClose={requestClose} open={open} position={Position.Modal} isModal>
        <div className="modal-content" >
          <form data-testid="modal-form" onSubmit={onSubmit}>
            {title && (
              <div className="modal-header">
                <h3>{title}</h3> {requestClose && <IconButton tag="CrossIcon" onClick={requestClose} title={accessibilityLabels.close} />}
              </div>
            )}
            {children}
            {(rightButtonProps || leftButtonProps) &&
              <div className="modal-footer">
                {leftButtonProps && <Button type="button" theme="inverse" {...leftButtonProps}><span>{leftButtonProps.label}</span></Button>}
                {rightButtonProps && <Button type="submit" {...rightButtonProps} onClick={onSubmit}>{rightButtonProps.label}</Button>}
              </div>
            }
          </form>
        </div>
      </Popup>
    </div>
  );
};

Modal.defaultProps = {
  title: '',
  className: '',
  rightButtonProps: null,
  leftButtonProps: null,
  children: null,
  open: false,
  requestClose: null,
};

export default Modal;
