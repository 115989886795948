export enum InputType {
  Text = 'text',
  Number = 'number',
  Textarea = 'textarea',
  Password = 'password',
  Select = 'select',
  Color = 'color',
}

export enum InputDateType {
  Date = 'date',
  DateTime = 'dateTime',
  Time = 'time'
}

export interface ILabelAndValue {
  label: string;
  value: string;
}

export interface IDateTime {
  date: Date;
  time?: string;
}
