import React from 'react';
import Skeleton from 'react-loading-skeleton';

import CircleGraph, { COLORS } from '../circleGraph/CircleGraph';
import FormattedValue, { CounterType } from '../formattedValue/FormattedValue';
import Orb from '../orb/Orb';

import './vacationWidget.scss';

interface IVacationItem {
  value?: { hours?: string, minutes?: string };
  description: string;
}

interface Props {
  vacation: IVacationItem[];
  emptyLabel: string;
  title: string;
  totalLabel: string;
  isLoading?: boolean;
}

class VacationWidget extends React.PureComponent<Props> {
  static defaultProps: Record<string, unknown>;
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this._isMounted = true; // needs to be underscored
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getValues = () => {
    let totalTime = 0;
    const valuesWithConvertedMinutes = this.props.vacation.reduce((values, item) => {
      if (!item.value) return values;
      const { hours, minutes } = item.value;
      const minutesInPercentage = parseInt(minutes) / 60;
      const hoursWithMinutes = parseInt(hours) + minutesInPercentage;
      totalTime += hoursWithMinutes;
      values.push(hoursWithMinutes);
      return values;
    }, []);

    return valuesWithConvertedMinutes.map(value => ((value / (totalTime || 1)) * 100));
  }

  getTotal = () => {
    const [hours, minutes] = this.props.vacation.reduce((total, item) => {
      return item.value ? [total[0] + parseInt(item.value.hours), total[1] + parseInt(item.value.minutes)] : total;
    }, [0, 0]);

    return {
      hours: hours + Math.floor(minutes / 60),
      minutes: minutes % 60,
    };
  }

  renderLegend = (item: IVacationItem, i: number) => {
    return (
      <div className="legend-item" key={i}>
        <FormattedValue type={CounterType.Time} value={{ hours: parseInt(item.value?.hours || '0'), minutes: parseInt(item.value?.minutes || '0') }} description={item.description} />
        <Orb color={COLORS[i].color} />
      </div>
    );
  }

  render() {
    return (
      <div className="vacation-content">
        {(!this.props.isLoading && this.props.vacation.length === 0)
          ? <p className="empty-text">{this.props.emptyLabel}</p>
          : <React.Fragment>
            {this.props.isLoading
              ? <Skeleton circle={true} height={180} width={180} />
              : (
                <div className="graph-container">
                  <CircleGraph values={this.getValues()} />
                  <div className="inner-graph">
                    <FormattedValue large value={this.getTotal()} type={CounterType.Time} description={this.props.totalLabel} />
                  </div>
                </div>
              )}
            <div className="vacation-legend">
              {this.props.isLoading
                ? <Skeleton width={300} height={30} />
                : this.props.vacation.map(this.renderLegend)
              }
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

VacationWidget.defaultProps = {
  isLoading: false,
};

export default VacationWidget;
